import { call, put, takeEvery } from 'redux-saga/effects';
import * as authApi from '../api/auth';
import { loginWithGithub } from 'github-oauth-popup'
import {
  USER_LOGIN_SUCCEEDED, USER_LOGIN_FAILED, USER_LOGIN_REQUESTED,
  USER_GITHUB_LOGIN_REQUESTED,
  USER_FACEBOOK_LOGIN_REQUESTED,
  USER_GOOGLE_LOGIN_REQUESTED,
  USER_LOGOUT_SUCCEEDED, 
  USER_LOGOUT_FAILED, 
  USER_LOGOUT_REQUESTED, 
  USER_REGISTER_SUCCEEDED, 
  USER_REGISTER_FAILED, 
  USER_REGISTER_REQUESTED
} from '../constants/actions';


function* register(action) {
  try {
    const res = yield call(authApi.register, action.payload);
    const json = yield res.json();
    yield put({ type: USER_REGISTER_SUCCEEDED });
    sessionStorage.setItem('user_token', json.key);
    action.navigate('/dashboard');
    yield action.enqueueSnackbar('Register success', {variant: 'success'})
  } catch (e) {
    yield put({ type: USER_REGISTER_FAILED, message: e.message });
    const json = yield e?.response?.json()
    yield action.enqueueSnackbar(json?.email?.length ? json.email[0] : 'Register failed'  , {variant: 'error'})
  }
}

function* login(action) {
  try {
    sessionStorage.removeItem('user_token');
    const res = yield call(authApi.login, action.payload);
    const json = yield res.json();
    yield put({ type: USER_LOGIN_SUCCEEDED, user: json });
    yield sessionStorage.setItem('user_token', json.key);
    yield action.navigate('/dashboard/store');
    yield action.enqueueSnackbar('Login success', {variant: 'success'})
  } catch (e) {
    yield put({ type: USER_LOGIN_FAILED, message: e.message });
    if(e?.cause?.status === 400) {
      yield action.enqueueSnackbar('Wrong login or password', {variant: 'error'})
    }
  }
}

function getFacebookStatus() {
  return new Promise((resolve) => {
    FB.getLoginStatus(function (response) {
      resolve(response)
    });

  })
}

/**
 * @Todo handle facebook connection error
 * @param {*} action 
 */
function* facebookLogin(action) {
  try {
    let result = yield getFacebookStatus()
    if (result.status !== 'connected') {
      yield FB.login()
      result = yield getFacebookStatus()
    }

    if (result.status === 'connected') {
      console.log(result.authResponse.accessToken)
      const res = yield call(authApi.facebookLogin, result.authResponse.accessToken);
      const resJson = yield res.json();
      yield put({ type: USER_LOGIN_SUCCEEDED, user: resJson });
      sessionStorage.setItem('user_token', resJson.key);
      action.navigate('/dashboard/store');
    }
  } catch (e) {
  }
}

function googleSignin() {
  return new Promise((resolve) => {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signIn().then(function (user) {
      console.log('here 1')
      resolve(user)
    });
  })
}

/**
 * @Todo handle facebook connection error
 * @param {*} action 
 */
function* googleLogin(action) {
  try {
    yield googleSignin()
    const user = gapi.auth2.getAuthInstance().currentUser.get()
    const res = yield call(authApi.facebookLogin, user.access_token);
    const resJson = yield res.json();
    yield put({ type: USER_LOGIN_SUCCEEDED, user: resJson });
    sessionStorage.setItem('user_token', resJson.key);
    action.navigate('/dashboard');
  } catch (e) {
  }
}

/**
 * @Todo handle google connection error
 * @param {*} action 
 */
function* githubLogin(action) {
  try {
    const gitRes = yield loginWithGithub({
      client_id: '7b4abe9878477390a074',
      scope: 'read:user'
    })
    const res = yield call(authApi.googleLogin, gitRes.code);
    const resJson = yield res.json();
    yield put({ type: USER_LOGIN_SUCCEEDED, user: resJson });
    sessionStorage.setItem('user_token', resJson.key);
    action.navigate('/dashboard');
  } catch (e) {
  }
}

function* logout(action) {
  try {
    yield call(authApi.logout);
    sessionStorage.removeItem('user_token');
    yield put({ type: USER_LOGOUT_SUCCEEDED });
    action.navigate('/login');
  } catch (e) {
    console.error(e)
    yield put({ type: USER_LOGOUT_FAILED, message: e.message });
  }
}

export function* rootSaga() {
  yield takeEvery(USER_REGISTER_REQUESTED, register);
  yield takeEvery(USER_LOGIN_REQUESTED, login);
  yield takeEvery(USER_FACEBOOK_LOGIN_REQUESTED, facebookLogin);
  yield takeEvery(USER_GOOGLE_LOGIN_REQUESTED, googleLogin);
  yield takeEvery(USER_GITHUB_LOGIN_REQUESTED, githubLogin);
  yield takeEvery(USER_LOGOUT_REQUESTED, logout);
}
