import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box, Card, Link, Typography, Button, Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
//
import Label from '../Label';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ greffon, onUse, onBuy }) {
  const { name, logo, status } = greffon;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}
        <ProductImgStyle alt={name} src={logo} />
      </Box>

      <Stack spacing={3} sx={{ p: 1.5 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Button sx={{ marginLeft: 'auto' }} variant="outlined" onClick={onUse}>Use with greffer</Button>
          <Button onClick={onBuy} variant="contained">Buy</Button>
        </Stack>
      </Stack>
    </Card>
  );
}
