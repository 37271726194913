import { useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '@mui/lab';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '../Dialog';
import Form from '../@rjsf-extend';


export default function FormDialog({
  withGreffer, open, loading, onClose, onCreateGreffon, greffon, greffers,
}) {
  const formRef = useRef([]);
  const { name, configurations: greffonConfigurations } = greffon;
  let valid = []
  let data = []

  const schema = {
    type: "object",
    required: ["name"],
    properties: {
      name: { type: "string", title: "Greffon Name", default: name },
    }
  };

  if (withGreffer) {
    schema.required.push("greffer")
    schema.properties["greffer"] = {
      type: "string", title: "Greffer",
      enum: greffers.map((greffer) => greffer.id),
      enumNames: greffers.map((greffer) => greffer.name)
    }
  }
  const configurations = [{ schema }, ...greffonConfigurations]
  useEffect(() => {
    valid = new Array(configurations.length)
    data = new Array(configurations.length)
    formRef.current = new Array(configurations.length);

  }, [configurations]);
  const onSubmit = (e) => {
    formRef.current.forEach((_, index) => {
      valid[index] = false
    })
    formRef.current.forEach((current) => {
      current.onSubmit(e)
    })
  }
  const onFormValid = ({ formData }, configuration, index) => {
    valid[index] = true
    data[index] = { ...configuration, value: formData }
    if (!valid.some((isValid) => !isValid)) {
      onCreateGreffon({
        name: data[0].value.name,
        greffer: data[0].value.greffer,
        configurations: data.slice(1).map((configuration) => ({
          value: configuration.value,
          greffon_version_configuration_id: configuration.id,
        })),
      })
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create your Greffon</DialogTitle>
      <DialogContent>
        {
          configurations.map((configuration, index) => (
            <Form
              ref={el => formRef.current[index] = el}
              key={index}
              schema={configuration.schema}
              onSubmit={(formData) => onFormValid(formData, configuration, index)}
              formData={configuration.default_value}
              children
            />
          ))
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={onSubmit}>Install</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
