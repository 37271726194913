import {
  call, put, race, delay, take, takeEvery, select,
} from 'redux-saga/effects';
import * as upsertApi from '../api/greffon';
import * as api from '../api';
import {
  USER_GET_ALL_GREFFONS_REQUESTED,
  USER_GET_ALL_GREFFONS_FAILED,
  USER_GET_ALL_GREFFONS_SUCCEEDED,
  USER_CREATE_MY_GREFFON_SUCCEEDED,
  USER_CREATE_MY_GREFFON_REQUEST,
  USER_CREATE_MY_GREFFON_FAILED,
  USER_UPDATE_MY_GREFFON_SUCCEEDED,
  USER_UPDATE_MY_GREFFON_REQUESTED,
  USER_UPDATE_MY_GREFFON_FAILED,
  USER_GET_ALL_MY_GREFFONS_REQUESTED,
  USER_GET_ALL_MY_GREFFONS_SUCCEEDED,
  USER_GET_ALL_MY_GREFFONS_FAILED,
  USER_START_MY_GREFFON_REQUESTED,
  USER_START_MY_GREFFON_SUCCEEDED,
  USER_START_MY_GREFFON_FAILED,
  USER_STOP_MY_GREFFON_REQUESTED,
  USER_STOP_MY_GREFFON_SUCCEEDED,
  USER_STOP_MY_GREFFON_FAILED,
  USER_OPEN_MY_GREFFON_REQUESTED,
  USER_OPEN_MY_GREFFON_PAGE,
  USER_CLOSE_MY_GREFFON_PAGE,
  GREFFON_CATEGORY_FAILED,
  GREFFON_CATEGORY_SUCCEEDED,
  GREFFON_CATEGORY_REQUESTED,
} from '../constants/actions';

function* getAll(action) {
  try {
    const res = yield call(upsertApi.getAll);
    const json = yield res.json();
    yield put({ type: USER_GET_ALL_GREFFONS_SUCCEEDED, payload: json });
  } catch (e) {
    yield put({ ...action, type: USER_GET_ALL_GREFFONS_FAILED, error: e });
  }
}

function* getCategories(action) {
  try {
    const res = yield call(upsertApi.getCategories);
    const json = yield res.json();
    yield put({ type: GREFFON_CATEGORY_SUCCEEDED, payload: json });
  } catch (e) {
    yield put({ ...action, type: GREFFON_CATEGORY_FAILED, error: e });
  }
}

function* getAllInstances(action) {
  try {
    const res = yield call(upsertApi.getAllInstances);
    const json = yield res.json();
    yield put({ type: USER_GET_ALL_MY_GREFFONS_SUCCEEDED, payload: json });
  } catch (e) {
    yield put({ ...action,type: USER_GET_ALL_MY_GREFFONS_FAILED, error: e });
  }
}

function* createMyGreffon(action) {
  try {
    const creatingGreffon = yield select((state) => state.greffon.creatingGreffon);
    const res = yield call(upsertApi.upsertMyGreffon, {
      greffon_version_id: creatingGreffon.id,
      ...action.payload,
      greffer_id: action.payload.greffer,
    });
    const json = yield res.json();
    yield put({ type: USER_CREATE_MY_GREFFON_SUCCEEDED, payload: json });
    yield action.enqueueSnackbar("Greffon created", {variant: 'success'});
  } catch (e) {
    yield put({ ...action, type: USER_CREATE_MY_GREFFON_FAILED, error: e });
    yield action.enqueueSnackbar("Greffon failed to create", {variant: 'error'});
  }
}

function* updateMyGreffon(action) {
  try {
    const res = yield call(upsertApi.upsertMyGreffon, action.payload);
    const json = yield res.json();
    yield put({ type: USER_UPDATE_MY_GREFFON_SUCCEEDED, payload: json });
    yield action.enqueueSnackbar("Greffon updated", {variant: 'success'});
  } catch (e) {
    yield put({ ...action, type: USER_UPDATE_MY_GREFFON_FAILED, error: e });
    yield action.enqueueSnackbar("Greffon failed to update", {variant: 'error'});
  }
}

function* startMyGreffon(action) {
  try {
    const res = yield call(upsertApi.startMyGreffon, { greffon_instance_id: action.payload.id });
    const json = yield res.json();
    yield put({ type: USER_START_MY_GREFFON_SUCCEEDED, payload: json });
    yield action.enqueueSnackbar("Greffon started", {variant: 'success'});
  } catch (e) {
    yield put({ ...action, type: USER_START_MY_GREFFON_FAILED, error: e });
    yield action.enqueueSnackbar("Greffon failed to start", {variant: 'error'});
  }
}

function* stopMyGreffon(action) {
  try {
    const res = yield call(upsertApi.stopMyGreffon, { greffon_instance_id: action.payload.id })
    const json = yield res.json();
    yield put({ type: USER_STOP_MY_GREFFON_SUCCEEDED, payload: json });
    yield action.enqueueSnackbar("Greffon stopped", {variant: 'success'});
  } catch (e) {
    yield put({...action, type: USER_STOP_MY_GREFFON_FAILED, error: e });
    yield action.enqueueSnackbar("Greffon failed to stop", {variant: 'error'});
  }
}
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function* openMyGreffon(action) {
  setCookie('test', 'test', 2)
  yield window.open(`${api.PROTOCOL}://${action.payload.fields[0].id}.${api.DOMAIN}`, '_blank', 'noopener');
}

function* pollGeffon() {
  while (true) {
      yield put({type: USER_GET_ALL_MY_GREFFONS_REQUESTED});
      yield delay(10000);
  }
}

export function* watchPollSaga() {
	while (true) {
  	yield take(USER_OPEN_MY_GREFFON_PAGE);
    yield race([
      call(pollGeffon),
      take(USER_CLOSE_MY_GREFFON_PAGE)
    ]);
  }
}

export function* watchRequestAllGreffon() {
  yield takeEvery(USER_GET_ALL_GREFFONS_REQUESTED, getAll);
}

export function* watchRequestAllCategories() {
  yield takeEvery(USER_OPEN_MY_GREFFON_PAGE, getCategories);
  yield takeEvery(GREFFON_CATEGORY_REQUESTED, getCategories);
}

export function* watchRequestAllGreffonInstance() {
  yield takeEvery(USER_OPEN_MY_GREFFON_PAGE, getAllInstances);
  yield takeEvery(USER_GET_ALL_MY_GREFFONS_REQUESTED, getAllInstances);
}

export function* watchRequestUpdateMyGreffon() {
  yield takeEvery(USER_UPDATE_MY_GREFFON_REQUESTED, updateMyGreffon);
}
export function* watchRequestCreateMyGreffon() {
  yield takeEvery(USER_CREATE_MY_GREFFON_REQUEST, createMyGreffon);
}

export function* watchRequestStartMyGreffon() {
  yield takeEvery(USER_START_MY_GREFFON_REQUESTED, startMyGreffon);
}

export function* watchRequestStopMyGreffon() {
  yield takeEvery(USER_STOP_MY_GREFFON_REQUESTED, stopMyGreffon);
}

export function* watchRequestOpenMyGreffon() {
  yield takeEvery(USER_OPEN_MY_GREFFON_REQUESTED, openMyGreffon);
}
