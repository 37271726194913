import {
  USER_REGISTER_SUCCEEDED, 
  USER_REGISTER_FAILED, 
  USER_REGISTER_REQUESTED, 
  USER_LOGIN_SUCCEEDED, 
  USER_LOGIN_FAILED, 
  USER_LOGIN_REQUESTED,
  USER_LOGOUT_SUCCEEDED, 
  USER_LOGOUT_FAILED, 
  USER_LOGOUT_REQUESTED } from '../constants/actions';

const initialState = { token: '', registring: false, me: {} };

function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_REQUESTED:
      return { ...state, loading: true };
    case USER_LOGIN_SUCCEEDED:
      return { ...state, ...action, loading: false };
    case USER_LOGIN_FAILED:
      return { ...state, loading: false };
      case USER_REGISTER_REQUESTED:
        return { ...state, registring: true };
      case USER_REGISTER_SUCCEEDED:
        return { ...state, ...action, registring: false };
      case USER_REGISTER_FAILED:
        return { ...state, registring: false };
    case USER_LOGOUT_REQUESTED:
      return { ...state, loading: true };
    case USER_LOGOUT_SUCCEEDED:
      return { ...state, token: '', me: {}, loading: false };
    case USER_LOGOUT_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default userReducer;
