import PropTypes from 'prop-types';
import Presentational from '../presentational/Presentational'
// material
import { Grid } from '@mui/material';
import GrefferCard from './GrefferCard';

// ----------------------------------------------------------------------

GrefferList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function GrefferList({ ids, Component }) {
  return (
    <Grid container spacing={3}>
      {ids.map((id) => (
        <Grid key={id} item xs={12} sm={12} md={6}>
          <Presentational id={id} Component={Component} />
        </Grid>
      ))}
    </Grid>
  );
}
