

import { takeEvery } from 'redux-saga/effects';
import {
    USER_CREATE_GREFFER_FAILED,
    USER_START_MY_GREFFON_FAILED,
    USER_GET_ALL_MY_GREFFONS_FAILED,
    USER_GET_ALL_GREFFONS_FAILED,
    USER_GET_ALL_GREFFERS_FAILED,
    USER_STOP_MY_GREFFON_FAILED,
    USER_UPDATE_MY_GREFFON_FAILED
} from '../constants/actions';

function* errorHandler({ navigate, error }) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
        navigate && navigate('/login')
    }

}
export function* watchErrors() {
    yield takeEvery(USER_GET_ALL_GREFFERS_FAILED, errorHandler);
    yield takeEvery(USER_CREATE_GREFFER_FAILED, errorHandler);
    yield takeEvery(USER_STOP_MY_GREFFON_FAILED, errorHandler);
    yield takeEvery(USER_START_MY_GREFFON_FAILED, errorHandler);
    yield takeEvery(USER_GET_ALL_GREFFONS_FAILED, errorHandler);
    yield takeEvery(USER_GET_ALL_MY_GREFFONS_FAILED, errorHandler);
    yield takeEvery(USER_UPDATE_MY_GREFFON_FAILED, errorHandler);
}