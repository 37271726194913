import instance from './instance';

export function getAll() {
  return instance.get('/api/greffer/', {});
}
export function createGreffer(data) {
  return instance.post('/api/greffer/', { data });
}

export function acceptRegister({ id }) {
  return instance.post(`/api/greffer/register/accept/${id}/`, { });
}
