import {
  USER_GET_ALL_GREFFONS_FAILED,
  USER_GET_ALL_GREFFONS_REQUESTED,
  USER_GET_ALL_GREFFONS_SUCCEEDED,
  USER_GET_ALL_MY_GREFFONS_FAILED,
  USER_GET_ALL_MY_GREFFONS_REQUESTED,
  USER_GET_ALL_MY_GREFFONS_SUCCEEDED,
  USER_CLOSE_CREATING_GREFFON,
  USER_CREATING_GREFFON,
  USER_CREATE_MY_GREFFON_REQUEST,
  USER_CREATE_MY_GREFFON_SUCCEEDED,
  USER_CREATE_MY_GREFFON_FAILED,
  USER_UPDATE_MY_GREFFON_REQUESTED,
  USER_UPDATE_MY_GREFFON_FAILED,
  USER_UPDATE_MY_GREFFON_SUCCEEDED,
  USER_START_MY_GREFFON_REQUESTED,
  USER_START_MY_GREFFON_SUCCEEDED,
  USER_START_MY_GREFFON_FAILED,
  USER_STOP_MY_GREFFON_REQUESTED,
  USER_STOP_MY_GREFFON_SUCCEEDED,
  USER_STOP_MY_GREFFON_FAILED,
  USER_CONFIGURING_MY_GREFFON,
  USER_CLOSE_CONFIGURING_MY_GREFFON,
  USER_UPDATE_MY_GREFFON_FILTER,
  USER_BUYING_GREFFON,
  USER_CLOSE_BUYING_GREFFON,
} from '../constants/actions';

const initialState = {
  ids: [],
  values: {},
  creating: false,
  buying: false,
  creatingGreffon: {},
  me: {
    ids: [],
    values: {},
    startLoading: {},
    stopLoading: {},
    configuring: false,
    configuringGreffon: {},
    filter: {}
  },
};

function greffon(state = initialState, action) {
  switch (action.type) {
    case USER_CREATING_GREFFON:
      return { ...state, creating: true, creatingGreffon: action.payload };
    case USER_CLOSE_CREATING_GREFFON:
      return { ...state, creating: false, buying: false, creatingGreffon: {} };
    case USER_BUYING_GREFFON:
      return { ...state, buying: true, creatingGreffon: action.payload };
    case USER_CLOSE_BUYING_GREFFON:
      return { ...state, buying: false, creatingGreffon: {} };
    case USER_CREATE_MY_GREFFON_REQUEST:
      return { ...state, loading: true };
    case USER_CREATE_MY_GREFFON_SUCCEEDED:
      return {
        ...state, loading: false, creating: false, buying: false, creatingGreffon: {},
      };
    case USER_CREATE_MY_GREFFON_FAILED:
      return {
        ...state, loading: false,
      };
    case USER_GET_ALL_GREFFONS_SUCCEEDED: {
      const ids = []
      const values = {}
      action.payload.greffons.forEach(element => {
        ids.push(element.id)
        values[element.id] = element
      });
      return { ...state, 
        ids, 
        values, 
        loading: false 
      };
    }
    case USER_GET_ALL_GREFFONS_REQUESTED:
      return { ...state, loading: true };
    case USER_GET_ALL_GREFFONS_FAILED:
      return { ...state, loading: false };
    case USER_CONFIGURING_MY_GREFFON:
      return {
        ...state,
        me: {
          ...state.me,
          configuring: true,
          configuringGreffon: action.payload,
        },
      };
    case USER_UPDATE_MY_GREFFON_REQUESTED:
      return {
        ...state,
        me: {
          ...state.me,
          values: {
            ...state.me.values,
            [action.payload.id]: action.payload
          },
          loading: true,
        }
      };
    case USER_UPDATE_MY_GREFFON_SUCCEEDED: 
      return {
        ...state,
        me: {
          ...state.me,
          loading: false, configuring: false, configuringGreffon: {},
        }
      };
    case USER_UPDATE_MY_GREFFON_FAILED:
      return {
        ...state,
        me: {
          ...state.me,
          loading: false, configuring: false, configuringGreffon: {},
        }
      };
    case USER_UPDATE_MY_GREFFON_FILTER:
      return {
        ...state,
        me: {
          ...state.me,
          filter: action.payload,
        }
      }
    case USER_CLOSE_CONFIGURING_MY_GREFFON:
      return {
        ...state,
        me: {
          ...state.me,
          configuring: false,
          configuringGreffon: {},
        },
      };
    case USER_GET_ALL_MY_GREFFONS_SUCCEEDED: {
      const ids = []
      const values = {}
      action.payload.greffons.forEach(element => {
        ids.push(element.id)
        values[element.id] = element
      });
      return {
        ...state,
        me: {
          ...state.me,
          ids,
          values,
          loading: false,
        },
      };
    }
    case USER_GET_ALL_MY_GREFFONS_REQUESTED:
      return {
        ...state,
        me: {
          ...state.me,
          loading: true,
        },
      };
    case USER_GET_ALL_MY_GREFFONS_FAILED:
      return {
        ...state,
        me: {
          ...state.me,
          loading: false,
        },
      };
    case USER_START_MY_GREFFON_REQUESTED:
      return {
        ...state,
        me: {
          ...state.me,
          startLoading: {
            [action.payload.id]: true,
          },
        },
      };
    case USER_START_MY_GREFFON_SUCCEEDED:
      return {
        ...state,
        me: {
          ...state.me,
          values: {
            ...state.me.values,
            [action.payload.id]: action.payload
          },
          startLoading: {
            [action.payload.id]: undefined,
          },
        },
      };
    case USER_START_MY_GREFFON_FAILED:
      return {
        ...state,
        me: {
          ...state.me,
          startLoading: {
            [action.payload.id]: undefined,
          },
        },
      };
    case USER_STOP_MY_GREFFON_REQUESTED:
      return {
        ...state,
        me: {
          ...state.me,
          stopLoading: {
            [action.payload.id]: true,
          },
        },
      };
    case USER_STOP_MY_GREFFON_SUCCEEDED:
      return {
        ...state,
        me: {
          ...state.me,
          values: {
            ...state.me.values,
            [action.payload.id]: action.payload
          },
          stopLoading: {
            [action.payload.id]: undefined,
          },
        },
      };
    case USER_STOP_MY_GREFFON_FAILED:
      return {
        ...state,
        me: {
          ...state.me,
          stopLoading: {
            [action.payload.id]: undefined,
          },
        },
      };
    default:
      return state;
  }
}

export default greffon;
