import {useState} from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FilterSidebar } from '../../components/filter-sidebar'
import withRouter from '../../components/withRouter'
import { withSnackbar } from 'notistack'
import { useFormik } from 'formik';
import { createSelector } from 'reselect'
import {
    USER_UPDATE_MY_GREFFON_FILTER
} from '../../constants/actions';

function GreffonFilter({ filters, values, onFilterChange }) {
    const [openFilter, setOpenFilter] = useState(false);
    const formik = useFormik({
        initialValues: values,
        onSubmit: () => {
            setOpenFilter(false);
        },
    });

    const { values: formikValues, resetForm, handleSubmit } = formik;

    const handleOpenFilter = () => {
        onFilterChange(formikValues)
        console.log(values)
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        onFilterChange(formikValues)
        console.log(values)
        setOpenFilter(false);
    };

    const handleResetFilter = () => {
        handleSubmit();
        resetForm();
        onFilterChange(formikValues)
    };

    return (
        <FilterSidebar
            values={formikValues}
            isOpenFilter={openFilter}
            filters={filters}
            formik={formik}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
        />
    )
}

const selectCategories = state => state.greffonCategories.values
const filters = createSelector(selectCategories, (categories) => {
    return [
        {
            name: 'Categories',
            id: 'categories',
            type: 'multi',
            choices: Object.values(categories).map((category) => category.name)
        },
        {
            name: 'Status',
            id: 'status',
            type: 'multi',
            choices: ['Created', 'Started', 'Stopped', 'Greffer is not running']
        },
    ]
})
const mapDispatchToProps = (dispatch, { router, enqueueSnackbar }) => ({
    onFilterChange: (payload) => dispatch({
        type: USER_UPDATE_MY_GREFFON_FILTER,
        payload,
        navigate: router?.navigate,
        enqueueSnackbar,
    }),
});

const mapStateProps = (state, { id }) => ({
    filters: filters(state),
    values: state.greffon.me.filter
})
export default compose(withSnackbar, withRouter, connect(mapStateProps, mapDispatchToProps))(GreffonFilter);

