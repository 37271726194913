import React from 'react';

import Button from '@mui/material/Button';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-eva/file-add-fill';
import arrowUpward from '@iconify/icons-eva/arrow-up-fill';
import arrowDownward from '@iconify/icons-eva/arrow-down-fill';
import remove from '@iconify/icons-eva/file-remove-fill';

const mappings = {
  remove,
  plus: add,
  'arrow-up': arrowUpward,
  'arrow-down': arrowDownward,
};

function IconButton(props) {
  const {
    icon, className, iconProps, ...otherProps
  } = props;

  return (
    <Button {...otherProps} size="small">
      <Icon icon={mappings[icon]} {...iconProps} />
    </Button>
  );
}

export default IconButton;
