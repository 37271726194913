import { all } from 'redux-saga/effects';
import * as userWatches from './user';
import * as greffonWatches from './greffon';
import * as grefferWatches from './greffer';
import * as errorWatches from './error';

export default function* rootSaga() {
  yield all([
    ...Object.entries(userWatches).map(([_, user]) => user()),
    ...Object.entries(greffonWatches).map(([_, greffon]) => greffon()),
    ...Object.entries(grefferWatches).map(([_, greffer]) => greffer()),
    ...Object.entries(errorWatches).map(([_, error]) => error()),
  ]);
}
