import instance from './instance';

export function register(data) {
  return instance.post('/api/auth/registration/', { data });
}

export function login(data) {
  return instance.post('/api/auth/login/', { data });
}

export function facebookLogin(accessToken) {
  return instance.post('/api/auth/facebook/', { data: { access_token: accessToken } });
}

export function googleLogin(code) {
  return instance.post('/api/auth/github/', { data: { code } });
}

export function logout() {
  return instance.post('/api/auth/logout/', {});
}
