import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  root: {
    marginTop: 5,
  },
});

function DescriptionField({ description }) {
  const classes = useStyles();
  if (description) {
    return (
      <Typography variant="subtitle2" className={classes.root}>
        {description}
      </Typography>
    );
  }

  return null;
}

export default DescriptionField;
