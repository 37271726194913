import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { withSnackbar } from 'notistack';
// material
import { Container, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import withRouter from '../../components/withRouter';
// components
import Page from '../../components/Page';
import {
  GrefferSort,
  GrefferList,
  GrefferDialog,
  GrefferFilterSidebar,
} from '../../components/greffer';
import GrefferCard from './Greffer'
import {
  USER_CLOSE_CREATING_GREFFER,
  USER_CLOSE_GREFFER_PAGE,
  USER_CREATE_GREFFER_REQUEST,
  USER_CREATING_GREFFER, USER_GET_ALL_GREFFERS_REQUESTED, USER_OPEN_GREFFER_PAGE,
} from '../../constants/actions';

// ----------------------------------------------------------------------

function Greffer({
  onOpenCreateDialogGreffer, enterOnGreffer,  getGreffers, leaveGreffer, OnCreateDialogClose, onCreateGreffer, onAcceptRegisterGreffer, createDialogOpen, loading, greffers,
}) {
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    enterOnGreffer();
    return leaveGreffer
  }, []);
  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: '',
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  return (
    <Page title="Dashboard: Greffers">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Greffers
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <GrefferFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <GrefferSort /> */}
            <Button
              variant="contained"
              to="#"
              startIcon={<Icon icon={plusFill} />}
              onClick={onOpenCreateDialogGreffer}
            >
              Add Greffer
            </Button>
          </Stack>
        </Stack>
        <GrefferDialog
          open={createDialogOpen}
          loading={loading}
          onCreateGreffer={onCreateGreffer}
          onClose={OnCreateDialogClose}
        />
        <GrefferList ids={greffers} Component={GrefferCard} />
      </Container>
    </Page>
  );
}

const mapDispatchToProps = (dispatch, { router, enqueueSnackbar }) => ({
  enterOnGreffer: (payload) => dispatch({
    type: USER_OPEN_GREFFER_PAGE,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  leaveGreffer: (payload) => dispatch({
    type: USER_CLOSE_GREFFER_PAGE,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  getGreffers: (payload) => dispatch({
    type: USER_GET_ALL_GREFFERS_REQUESTED,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  OnCreateDialogClose: (payload) => dispatch({
    type: USER_CLOSE_CREATING_GREFFER,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  onOpenCreateDialogGreffer: (payload) => dispatch({
    type: USER_CREATING_GREFFER,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  onCreateGreffer: (payload) => dispatch({
    type: USER_CREATE_GREFFER_REQUEST,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
});

export default compose(withSnackbar, withRouter, connect((state, ownProps) => ({
  greffers: state.greffer.ids,
  createDialogOpen: state.greffer.creating,
  loading: state.greffer.loading,
}), mapDispatchToProps))(Greffer);
