import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/login/Login';
import { Register } from './pages/register';
import { GreffonList } from './pages/greffon';
import { MyGreffonList } from './pages/my-greffon';
import { GrefferList } from './pages/greffer';
import { Page404 } from './pages/page-404';

// ----------------------------------------------------------------------

export default function Router(props) {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/greffons" replace /> },
        { path: 'store', element: <GreffonList /> },
        { path: 'my-greffons', element: <MyGreffonList /> },
        { path: 'greffers', element: <GrefferList /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <Page404 /> },
        { path: '/', element: <Navigate to="/dashboard/store" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
