import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import { GrefferCard } from '../../components/greffer'
import withRouter from '../../components/withRouter'
import { USER_ACCEPT_REGISTER_GREFFER_REQUESTED } from '../../constants/actions';

function GrefferContainer({  onAcceptRegister,  greffer, ...other }) {
    return (
        <GrefferCard  greffer={greffer} {...other} onAcceptRegister={() => onAcceptRegister(greffer)} />
    )
}

const mapDispatchToProps = (dispatch, { router, enqueueSnackbar }) => ({
    onAcceptRegister: (payload) => dispatch({
        type: USER_ACCEPT_REGISTER_GREFFER_REQUESTED,
        payload,
        navigate: router?.navigate,
        enqueueSnackbar,
      }),
});
const mapStateProps = (state, { id }) => ({
    greffer: state.greffer.values[id],
    acceptLoading: state.greffer.acceptLoading[id],
})
export default compose(withSnackbar, withRouter, connect(mapStateProps, mapDispatchToProps))(GrefferContainer);

