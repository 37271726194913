import {
    GREFFON_CATEGORY_REQUESTED,
    GREFFON_CATEGORY_SUCCEEDED,
    GREFFON_CATEGORY_FAILED
  } from
    '../constants/actions';
  
const initialState = { 
    ids: [],
    values: {},
    requesting: false,
};



function greffonCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case GREFFON_CATEGORY_REQUESTED: {
        return { ...state, requesting: true };
    }
    case GREFFON_CATEGORY_SUCCEEDED: {
        const ids = []
        const values = {}
        action.payload.categories.forEach(element => {
          ids.push(element.id)
          values[element.id] = element
        });
        return { ...state, ids, values, requesting: false };
    }
    case GREFFON_CATEGORY_SUCCEEDED: {
        return { ...state, requesting: false };
    }
    default:
      return state;
  }
}

export default greffonCategoryReducer;
