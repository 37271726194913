import { connect } from 'react-redux';
import { compose } from 'redux';
import { GreffonCard } from '../../components/greffon'
import withRouter from '../../components/withRouter'
import { withSnackbar } from 'notistack'
import { USER_BUYING_GREFFON, USER_CREATING_GREFFON } from '../../constants/actions'


function GreffonContainer({ greffon, onOpenCreateDialogGreffon, onOpenBuyDialogGreffon }) {
    return (
        <GreffonCard greffon={greffon} onUse={() => onOpenCreateDialogGreffon(greffon)} onBuy={() => onOpenBuyDialogGreffon(greffon)} />
    )
}

const mapDispatchToProps = (dispatch, { router, enqueueSnackbar }) => ({
    onOpenCreateDialogGreffon: (payload) => dispatch({
        type: USER_CREATING_GREFFON,
        payload,
        navigate: router?.navigate,
        enqueueSnackbar,
    }),
    onOpenBuyDialogGreffon: (payload) => dispatch({
        type: USER_BUYING_GREFFON,
        payload,
        navigate: router?.navigate,
        enqueueSnackbar,
    })
});
const mapStateProps = (state, { id }) => ({
    greffon: state.greffon.values[id]
})
export default compose(withSnackbar, withRouter, connect(mapStateProps, mapDispatchToProps))(GreffonContainer);

