import { Icon } from '@iconify/react';
import settingsOutline from '@iconify/icons-eva/settings-2-outline';
import shoppingBall from '@iconify/icons-eva/shopping-bag-fill';
import monitorOutline from '@iconify/icons-eva/monitor-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'store',
    path: '/dashboard/store',
    icon: getIcon(shoppingBall),
  },
  {
    title: 'my greffons',
    path: '/dashboard/my-greffons',
    icon: getIcon(settingsOutline),
  },
  {
    title: 'greffers',
    path: '/dashboard/greffers',
    icon: getIcon(monitorOutline),
  },
];

export default sidebarConfig;
