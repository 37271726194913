import React from 'react';

import TextField from '@mui/material/TextField';

import { utils } from '@rjsf/core';

const { getDisplayLabel } = utils;

function TextWidget({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
  formContext,
  registry, // pull out the registry so it doesn't end up in the textFieldProps
  ...textFieldProps
}) {
  const _onChange = ({
    target: { value },
  }) => onChange(value === '' ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }) => onFocus(id, value);

  const displayLabel = getDisplayLabel(
    schema,
    uiSchema,
    /* TODO: , rootSchema */
  );
  const inputType = (type || schema.type) === 'string' ? 'text' : `${type || schema.type}`;

  return (
    <TextField
      id={id}
      placeholder={placeholder}
      label={displayLabel ? label || schema.title : false}
      autoFocus={autofocus}
      required={required}
      disabled={disabled || readonly}
      type={inputType}
      value={value || value === 0 ? value : ''}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      {...(textFieldProps)}
      variant="standard"
    />
  );
}

export default TextWidget;
