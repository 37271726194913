export const USER_REGISTER_REQUESTED = 'USER_REGISTER_REQUESTED';
export const USER_REGISTER_SUCCEEDED = 'USER_REGISTER_SUCCEEDED';
export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED';

export const USER_LOGIN_REQUESTED = 'USER_LOGIN_REQUESTED';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_FACEBOOK_LOGIN_REQUESTED = 'USER_FACEBOOK_LOGIN_REQUESTED';
export const USER_FACEBOOK_LOGIN_SUCCEEDED = 'USER_FACEBOOK_LOGIN_SUCCEEDED';
export const USER_FACEBOOK_LOGIN_FAILED = 'USER_FACEBOOK_LOGIN_FAILED';

export const USER_GOOGLE_LOGIN_REQUESTED = 'USER_GOOGLE_LOGIN_REQUESTED';
export const USER_GOOGLE_LOGIN_SUCCEEDED = 'USER_GOOGLE_LOGIN_SUCCEEDED';
export const USER_GOOGLE_LOGIN_FAILED = 'USER_GOOGLE_LOGIN_FAILED';

export const USER_GITHUB_LOGIN_REQUESTED = 'USER_GITHUB_LOGIN_REQUESTED';
export const USER_GITHUB_LOGIN_SUCCEEDED = 'USER_GITHUB_LOGIN_SUCCEEDED';
export const USER_GITHUB_LOGIN_FAILED = 'USER_GITHUB_LOGIN_FAILED';

export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const USER_GET_ALL_GREFFONS_REQUESTED = 'USER_GET_ALL_GREFFONS_REQUESTED';
export const USER_GET_ALL_GREFFONS_SUCCEEDED = 'USER_GET_ALL_GREFFONS_SUCCEEDED';
export const USER_GET_ALL_GREFFONS_FAILED = 'USER_GET_ALL_GREFFONS_FAILED';

export const USER_GET_ALL_MY_GREFFONS_REQUESTED = 'USER_GET_ALL_MY_GREFFONS_REQUESTED';
export const USER_GET_ALL_MY_GREFFONS_SUCCEEDED = 'USER_GET_ALL_MY_GREFFONS_SUCCEEDED';
export const USER_GET_ALL_MY_GREFFONS_FAILED = 'USER_GET_ALL_MY_GREFFONS_FAILED';

export const USER_CREATE_MY_GREFFON_REQUEST = 'USER_CREATE_MY_GREFFON_REQUEST';
export const USER_CREATE_MY_GREFFON_SUCCEEDED = 'USER_CREATE_MY_GREFFON_SUCCEEDED';
export const USER_CREATE_MY_GREFFON_FAILED = 'USER_CREATE_MY_GREFFON_FAILED';

export const USER_UPDATE_MY_GREFFON_REQUESTED = 'USER_UPDATE_MY_GREFFON_REQUESTED';
export const USER_UPDATE_MY_GREFFON_SUCCEEDED = 'USER_UPDATE_MY_GREFFON_SUCCEEDED';
export const USER_UPDATE_MY_GREFFON_FAILED = 'USER_UPDATE_MY_GREFFON_FAILED';

export const USER_UPDATE_MY_GREFFON_FILTER = 'USER_UPDATE_MY_GREFFON_FILTER';

export const USER_START_MY_GREFFON_REQUESTED = 'USER_START_MY_GREFFON_REQUESTED';
export const USER_START_MY_GREFFON_SUCCEEDED = 'USER_START_MY_GREFFON_SUCCEEDED';
export const USER_START_MY_GREFFON_FAILED = 'USER_START_MY_GREFFON_FAILED';

export const USER_STOP_MY_GREFFON_REQUESTED = 'USER_STOP_MY_GREFFON_REQUESTED';
export const USER_STOP_MY_GREFFON_SUCCEEDED = 'USER_STOP_MY_GREFFON_SUCCEEDED';
export const USER_STOP_MY_GREFFON_FAILED = 'USER_STOP_MY_GREFFON_FAILED';

export const USER_OPEN_MY_GREFFON_REQUESTED = 'USER_OPEN_MY_GREFFON_REQUESTED';

export const USER_CONFIGURING_MY_GREFFON = 'USER_CONFIGURING_MY_GREFFON';
export const USER_CLOSE_CONFIGURING_MY_GREFFON = 'USER_CLOSE_CONFIGURING_MY_GREFFON';

export const USER_CREATING_GREFFON = 'USER_CREATING_GREFFON';
export const USER_BUYING_GREFFON = 'USER_BUYING_GREFFON';
export const USER_CLOSE_CREATING_GREFFON = 'USER_CLOSE_CREATING_GREFFON';
export const USER_CLOSE_BUYING_GREFFON = 'USER_CLOSE_BUYING_GREFFON';

export const USER_ACCEPT_REGISTER_GREFFER_REQUESTED = 'USER_ACCEPT_REGISTER_GREFFER_REQUESTED';
export const USER_ACCEPT_REGISTER_GREFFER_SUCCEEDED = 'USER_ACCEPT_REGISTER_GREFFER_SUCCEEDED';
export const USER_ACCEPT_REGISTER_GREFFER_FAILED = 'USER_ACCEPT_REGISTER_GREFFER_FAILED';

export const USER_GET_ALL_GREFFERS_REQUESTED = 'USER_GET_ALL_GREFFERS_REQUESTED';
export const USER_GET_ALL_GREFFERS_SUCCEEDED = 'USER_GET_ALL_GREFFERS_SUCCEEDED';
export const USER_GET_ALL_GREFFERS_FAILED = 'USER_GET_ALL_GREFFERS_FAILED';

export const USER_CREATING_GREFFER = 'USER_CREATING_GREFFER';
export const USER_CLOSE_CREATING_GREFFER = 'USER_CLOSE_CREATING_GREFFER';
export const USER_CREATE_GREFFER_REQUEST = 'USER_CREATE_GREFFER_REQUEST';
export const USER_CREATE_GREFFER_SUCCEEDED = 'USER_CREATE_GREFFER_SUCCEEDED';
export const USER_CREATE_GREFFER_FAILED = 'USER_CREATE_GREFFER_FAILED';

export const USER_OPEN_MY_GREFFON_PAGE = 'USER_OPEN_MY_GREFFON_PAGE';
export const USER_CLOSE_MY_GREFFON_PAGE = 'USER_CLOSE_MY_GREFFON_PAGE';

export const USER_OPEN_GREFFER_PAGE = 'USER_OPEN_GREFFER_PAGE';
export const USER_CLOSE_GREFFER_PAGE = 'USER_CLOSE_GREFFER_PAGE';

export const GREFFON_CATEGORY_REQUESTED = 'GREFFON_CATEGORY_REQUESTED';
export const GREFFON_CATEGORY_SUCCEEDED = 'GREFFON_CATEGORY_SUCCEEDED';
export const GREFFON_CATEGORY_FAILED = 'GREFFON_CATEGORY_FAILED';