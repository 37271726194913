import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// material
import { styled } from '@mui/material/styles';
import {
  Card, Stack, Link, Container, Typography,
} from '@mui/material';
import { withSnackbar } from 'notistack';
import withRouter from '../../components/withRouter';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/login-form';
import AuthSocial from '../../components/AuthSocial';
import { USER_LOGIN_REQUESTED, USER_GITHUB_LOGIN_REQUESTED, USER_FACEBOOK_LOGIN_REQUESTED, USER_GOOGLE_LOGIN_REQUESTED } from '../../constants/actions';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function Login({ loading,  onLogin, onGoogleLogin, onGithubLogin, onFacebookLogin }) {
  const navigate = useNavigate();
  return (
    <RootStyle title="Login">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to Greffon
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          {/* <AuthSocial onGoogleLogin={onGoogleLogin(navigate)} onGithubLogin={onGithubLogin(navigate)} onFacebookLogin={onFacebookLogin(navigate)} /> */}

          <LoginForm onLogin={onLogin(navigate)}  loading={loading} />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

const mapDispatchToProps = (dispatch,  { router, enqueueSnackbar }) => ({
  // dispatching actions returned by action creators
  onLogin: (navigate) => (payload) => dispatch({
    type: USER_LOGIN_REQUESTED,
    payload: {
      ...payload,
    },
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  onFacebookLogin: (navigate) => (payload) => dispatch({
    type: USER_FACEBOOK_LOGIN_REQUESTED,
    payload: {
      ...payload,
    },
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  onGoogleLogin: (navigate) => (payload) => dispatch({
    type: USER_GOOGLE_LOGIN_REQUESTED,
    payload: {
      ...payload,
    },
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  onGithubLogin: (navigate) => (payload) => dispatch({
    type: USER_GITHUB_LOGIN_REQUESTED,
    payload: {
      ...payload,
    },
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
});
export default compose(withSnackbar, withRouter, connect((state, ownProps) => ({
  loading: state.user.loading,
}), mapDispatchToProps))(Login);
