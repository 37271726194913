import instance from './instance';

export function upsertMyGreffon(data) {
  return instance.post('/api/greffon/instances/', { data });
}
export function getAll() {
  return instance.get('/api/greffon/', {});
}
export function startMyGreffon(data) {
  return instance.put('/api/greffer/', { data: { ...data, action: 'start' } });
}

export function stopMyGreffon(data) {
  return instance.put('/api/greffer/', { data: { ...data, action: 'stop' } });
}

export function getCategories() {
  return instance.get('/api/greffon/categories/', {});
}

export function getAllInstances() {
  return instance.get('/api/greffon/instances/', {});
}
