// material
import { Grid } from '@mui/material';
import Presentational from '../presentational/Presentational';
import GreffonCard from './MyGreffonCard';

// ----------------------------------------------------------------------

export default function GreffonList({Component, ids}) {
  return (
    <Grid container spacing={3}>
      {ids.map((id) => (
        <Grid key={id} item xs={12} sm={6} md={3}>
          <Presentational
            Component={Component}
            id={id}
          />
        </Grid>
      ))}
    </Grid>
  );
}
