import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
//
import Scrollbar from '../Scrollbar';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 'status', label: 'Status' },
  { value: 'newest', label: 'Newest' },
];



// ----------------------------------------------------------------------

FilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};
function FilterItem({ getFieldProps, type, id, name, choices, values }) {
  switch (type) {
    case 'multi': {
      return (
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {name}
          </Typography>
          <FormGroup>
            {choices.map((item, key) => (
              <FormControlLabel
                key={item}
                control={(
                  <Checkbox
                    {...getFieldProps(id)}
                    value={item}
                    checked={Boolean(values && values.indexOf(item) !== -1)}
                  />
                )}
                label={item}
              />
            ))}
          </FormGroup>
        </div>
      )
    }
    case 'option': {
      return (
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {name}
          </Typography>
          <RadioGroup {...getFieldProps(id)}>
            {choices.map((item) => (
              <FormControlLabel key={item} value={item} control={<Radio />} label={item} />
            ))}
          </RadioGroup>
        </div>
      )
    }
    case 'star': {
      return (
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {name}
          </Typography>
          <RadioGroup {...getFieldProps('rating')}>
            {choices.map((item, index) => (
              <FormControlLabel
                key={item}
                value={item}
                control={(
                  <Radio
                    disableRipple
                    color="default"
                    icon={<Rating readOnly value={4 - index} />}
                    checkedIcon={<Rating readOnly value={4 - index} />}
                  />
                )}
                label="& Up"
                sx={{
                  my: 0.5,
                  borderRadius: 1,
                  '& > :first-of-type': { py: 0.5 },
                  '&:hover': {
                    opacity: 0.48,
                    '& > *': { bgcolor: 'transparent' },
                  },
                  ...(values && values[item] && {
                    bgcolor: 'background.neutral',
                  }),
                }}
              />
            ))}
          </RadioGroup>
        </div>
      )
    }
  }
  return null
}

export default function FilterSidebar({
  filters,
  values,
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  formik,
}) {
  const { getFieldProps, handleChange } = formik;
  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Icon icon={roundFilterList} />}
        onClick={onOpenFilter}
      >
        Filters&nbsp;
      </Button>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
              sx: { width: 280, border: 'none', overflow: 'hidden' },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 1, py: 2 }}
            >
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                Filters
              </Typography>
              <IconButton onClick={onCloseFilter}>
                <Icon icon={closeFill} width={20} height={20} />
              </IconButton>
            </Stack>

            <Divider />

            <Scrollbar>
              <Stack spacing={3} sx={{ p: 3 }}>
                {filters.map((item) => (<FilterItem getFieldProps={getFieldProps} {...item} values={values[item.id]} />))}
              </Stack>
            </Scrollbar>

            <Box sx={{ p: 3 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={onResetFilter}
                startIcon={<Icon icon={roundClearAll} />}
              >
                Clear All
              </Button>
            </Box>
          </Drawer>
        </Form>
      </FormikProvider>
    </>
  );
}
