import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box, Stack, AppBar, Toolbar, IconButton,
} from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
import withRouter from '../../components/withRouter';
//
import Searchbar from './Searchbar';
import AccountPopoverComponent from '../../components/account-popover/AccountPopover';
import LanguagePopoverComponent from '../../components/language-popover/LanguagePopover';
import NotificationsPopoverComponent from '../../components/notification-popover/NotificationsPopover';
import { USER_LOGOUT_REQUESTED } from '../../constants/actions';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

const mapDispatchToProps = (dispatch, { router }) => ({
  onLogout: (payload) => dispatch({
      type: USER_LOGOUT_REQUESTED,
      payload,
      navigate: router?.navigate,
  })
});
const AccountPopover = compose(withRouter, connect((state, ownProps) => ({
  account: state.user.me,
}), mapDispatchToProps))(AccountPopoverComponent);
const LanguagePopover = connect(null, null)(LanguagePopoverComponent);
const NotificationsPopover = connect((state, ownProps) => ({
  notifications: [],
}), null)(NotificationsPopoverComponent);

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
