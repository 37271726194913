const initialState = { list: [] };

function notificationReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default notificationReducer;
