import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box, Card, Link, Typography, Stack, IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
//
import settingsOutline from '@iconify/icons-eva/settings-2-outline';
import { Icon } from '@iconify/react';
import Label from '../Label';

import * as status from '../../constants/status';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};
const greffonStop = -1
const satusToText = {
  [greffonStop]: 'greffer is not running',
  [status.GREFFON_CREATED]: 'created',
  [status.GREFFON_STARTED]: 'started',
  [status.GREFFON_STARTING]: 'starting',
  [status.GREFFON_STOPPING]: 'stopping',
  [status.GREFFON_STOPPED]: 'stopped',
};
export default function ShopProductCard({
  greffon, loading, onStop, onStart, onOpen, onConfigure,
}) {
  let { logo, name, status: greffonStatus, greffer: { status: grefferStatus} } = greffon;
  greffonStatus = grefferStatus != status.GREFFER_REGISTERED ? greffonStop : greffonStatus
  return (
    <Card sx={{
      height: '100%'
    }}>
      <Box sx={{ 
        pt: '100%', 
        position: 'relative'
         }}>
        <Label
          variant="filled"
          color={greffonStatus === greffonStop ? 'error' : 'primary'}
          sx={{
            zIndex: 9,
            top: 8,
            left: 8,
            position: 'absolute',
            textTransform: 'uppercase',
          }}
        >
          {satusToText[greffonStatus]}
        </Label>
        {
          (greffonStatus === status.GREFFON_STOPPED || greffonStatus === status.GREFFON_CREATED)
          && <IconButton
            sx={{
              zIndex: 9,
              top: 2,
              right: 2,
              position: 'absolute',
              textTransform: 'uppercase',
              backgroundColor: 'white',
              padding: '0',
            }}
            onClick={onConfigure}
            color="primary"
          >
            <Icon icon={settingsOutline} width={30} height={30} />
          </IconButton>}
        <ProductImgStyle alt={name} src={logo} />
      </Box>

      <Stack spacing={3} sx={{ p: 1.5 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row-reverse" alignItems="center" justifyContent="space-between">
          {
            (greffonStatus === status.GREFFON_STARTED)
            && <LoadingButton loading={loading} variant="contained" onClick={onOpen}>Open</LoadingButton>
          }
          {
            greffonStatus === status.GREFFON_STARTED
            && <LoadingButton loading={loading} variant="contained" color="error" onClick={onStop}>Stop</LoadingButton>
          }
          {
            (greffonStatus === status.GREFFON_STOPPED || greffonStatus === status.GREFFON_CREATED)
            && <LoadingButton loading={loading} variant="contained" onClick={onStart}>Start</LoadingButton>
          }
        </Stack>
      </Stack>
    </Card>
  );
}
