import { call, put, takeEvery, race, delay, take, } from 'redux-saga/effects';
import {
  USER_CREATE_GREFFER_FAILED, 
  USER_CREATE_GREFFER_REQUEST,
   USER_CREATE_GREFFER_SUCCEEDED, 
   USER_ACCEPT_REGISTER_GREFFER_REQUESTED,
   USER_ACCEPT_REGISTER_GREFFER_SUCCEEDED,
   USER_ACCEPT_REGISTER_GREFFER_FAILED,
   USER_GET_ALL_GREFFERS_FAILED, 
   USER_GET_ALL_GREFFERS_REQUESTED, 
   USER_GET_ALL_GREFFERS_SUCCEEDED,
   ENQUEUE_SNACKBAR,
   USER_OPEN_GREFFER_PAGE,
   USER_CLOSE_GREFFER_PAGE,
} from '../constants/actions';
import * as authApi from '../api/greffer';

function* createGreffer(action) {
  try {
    const res = yield call(authApi.createGreffer, action.payload);
    const json = yield res.json();
    yield put({ type: USER_CREATE_GREFFER_SUCCEEDED, payload: json });
    yield action.enqueueSnackbar("Greffer created", {variant: 'success'});
  } catch (e) {
    yield put({ ...action, type: USER_CREATE_GREFFER_FAILED,  error: e});
    yield action.enqueueSnackbar("Greffer failed to create", {variant: 'error'});
  }
}

function* getAll(action) {
  try {
    const res = yield call(authApi.getAll);
    const json = yield res.json();
    yield put({ type: USER_GET_ALL_GREFFERS_SUCCEEDED, payload: json });
  } catch (e) {
    yield put({ ...action, type: USER_GET_ALL_GREFFERS_FAILED, error: e });
  }
}
function* acceptRegister({payload, enqueueSnackbar}) {
  try {
    const res = yield call(authApi.acceptRegister, payload);
    const json = yield res.json();
    yield put({ type: USER_ACCEPT_REGISTER_GREFFER_SUCCEEDED, payload: json });
    yield enqueueSnackbar("Greffer registering", {variant: 'success'});
  } catch (e) {
    yield put({ type: USER_ACCEPT_REGISTER_GREFFER_FAILED, error: e, payload });
    yield enqueueSnackbar("Greffer failed to register", {variant: 'error'});
  }
}

function* pollGeffer() {
  while (true) {
      yield put({type: USER_GET_ALL_GREFFERS_REQUESTED});
      yield delay(10000);
  }
}

export function* watchPollSaga() {
	while (true) {
  	yield take(USER_OPEN_GREFFER_PAGE);
    yield race([
      call(pollGeffer),
      take(USER_CLOSE_GREFFER_PAGE)
    ]);
  }
}
export function* watchCreteGreffer() {
  yield takeEvery(USER_CREATE_GREFFER_REQUEST, createGreffer);
}

export function* watchRequestAllGreffer() {
  yield takeEvery(USER_GET_ALL_GREFFERS_REQUESTED, getAll);
}
export function* watchRequestAcceptRegisterGreffer() {
  yield takeEvery(USER_ACCEPT_REGISTER_GREFFER_REQUESTED, acceptRegister);
}
