import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Icon } from '@iconify/react';
import errorIcon from '@iconify/icons-eva/alert-circle-fill';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';

function ErrorList({ errors }) {
  return (
    <Paper elevation={2}>
      <Box mb={2} p={2}>
        <Typography variant="h6">
          Errors
        </Typography>
        <List dense>
          {errors.map((error, i) => (
            <ListItem key={i}>
              <ListItemIcon>
                <Icon icon={errorIcon} color="error" />
              </ListItemIcon>
              <ListItemText primary={error.stack} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
}

export default ErrorList;
