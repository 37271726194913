import { useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import Form from '../@rjsf-extend';
import Dialog from '../Dialog';

export default function FormDialog({
  greffon, open, loading, onClose, onSave,
}) {
  const formRef = useRef([]);
  const { configurations: greffonConfigurations, name } = greffon;
  let valid = []
  let data = []

  const schema = {
    type: "object",
    required: ["name"],
    properties: {
      name: { type: "string", title: "Greffon Name" },
    }
  };
  const configurations = [{ schema, value: {
    name, 
  } }, ...greffonConfigurations]
  useEffect(() => {
    valid = new Array(configurations.length)
    data = new Array(configurations.length)
    formRef.current = new Array(configurations.length);
  }, [configurations]);
  const onSubmit = (e) => {
    formRef.current.forEach((_, index) => {
      valid[index] = false
    })
    formRef.current.forEach((current) => {
      current.onSubmit(e)
    })
  }
  const onFormValid = ({ formData }, configuration, index) => {
    valid[index] = true
    data[index] = { ...configuration, value: formData }
    if (!valid.some((isValid) => !isValid)) {
      onSave({
        ...greffon,
        name: data[0].value.name,
        configurations: data.slice(1).map((configuration) => ({
          value: configuration.value,
          greffon_version_configuration_id: configuration.id,
        })),
      })
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update your Greffon</DialogTitle>
      <DialogContent>
          {
            configurations.map((configuration, index) => (
              <Form
                ref={el => formRef.current[index] = el}
                key={index}
                schema={configuration.schema}
                onSubmit={(formData) => onFormValid(formData, configuration, index)}
                formData={configuration.value}
                children
              />
            ))
          }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton type="submit" loading={loading} onClick={onSubmit}>Save</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
