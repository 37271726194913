import { useEffect } from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

export default function AuthController({ children }) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        if (!sessionStorage.getItem('user_token') && !(location.pathname in ['/login', '/register'])) {
            navigate('/login')
        }
    }, [])
    return (
        <>
            {children}
        </>
    );
}

