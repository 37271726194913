import React from 'react';

import { utils } from '@rjsf/core';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import IconButton from '../IconButton/IconButton';

const { ADDITIONAL_PROPERTY_FLAG } = utils;

function WrapIfAdditional({
  children,
  disabled,
  id,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  schema,
}) {
  const keyLabel = `${label} Key`; // i18n ?
  const additional = schema.hasOwnProperty(ADDITIONAL_PROPERTY_FLAG);
  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold',
  };

  if (!additional) {
    return <>{children}</>;
  }

  const handleBlur = ({ target }) => onKeyChange(target.value);

  return (
    <Grid container key={`${id}-key`} alignItems="center" spacing={2}>
      <Grid item xs>
        <FormControl fullWidth required={required}>
          <InputLabel>{keyLabel}</InputLabel>
          <Input
            defaultValue={label}
            disabled={disabled || readonly}
            id={`${id}-key`}
            name={`${id}-key`}
            onBlur={!readonly ? handleBlur : undefined}
            type="text"
          />
        </FormControl>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
      <Grid item>
        <IconButton
          icon="remove"
          tabIndex={-1}
          style={btnStyle}
          disabled={disabled || readonly}
          onClick={onDropPropertyClick(label)}
        />
      </Grid>
    </Grid>
  );
}

export default WrapIfAdditional;
