import {
  USER_GET_ALL_GREFFERS_SUCCEEDED,
  USER_GET_ALL_GREFFERS_FAILED,
  USER_GET_ALL_GREFFERS_REQUESTED,
  USER_CLOSE_CREATING_GREFFER,
  USER_CREATE_GREFFER_REQUEST,
  USER_CREATING_GREFFER,
  USER_CREATE_GREFFER_SUCCEEDED,
  USER_CREATE_GREFFER_FAILED,
  USER_ACCEPT_REGISTER_GREFFER_REQUESTED,
  USER_ACCEPT_REGISTER_GREFFER_SUCCEEDED,
  USER_ACCEPT_REGISTER_GREFFER_FAILED,
} from
  '../constants/actions';

const initialState = { ids: [], values: {}, acceptLoading: {}, creating: false };

function greffer(state = initialState, action) {
  switch (action.type) {
    case USER_CREATING_GREFFER:
      return { ...state, creating: true };
    case USER_CLOSE_CREATING_GREFFER:
      return { ...state, creating: false };
    case USER_CREATE_GREFFER_REQUEST:
      return { ...state, loading: true };
    case USER_CREATE_GREFFER_SUCCEEDED:
      return {
        ...state,
        ids: [...state.ids, action.payload.id],
        values: {
          ...state.values,
          [action.payload.id]: action.payload
        },
        loading: false,
        creating: false,
      };
    case USER_CREATE_GREFFER_FAILED:
      return { ...state, loading: false };
    case USER_GET_ALL_GREFFERS_SUCCEEDED: {
      const ids = []
      const values = {}
      action.payload.data.forEach(element => {
        ids.push(element.id)
        values[element.id] = element
      });
      return {
        ...state,
        ids,
        values,
        loading: false
      };
    }
    case USER_GET_ALL_GREFFERS_REQUESTED:
      return { ...state, loading: true };
    case USER_GET_ALL_GREFFERS_FAILED:
      return { ...state, loading: false };
    case USER_ACCEPT_REGISTER_GREFFER_REQUESTED:
      return {
        ...state,
        acceptLoading: {
          ...state.acceptLoading,
          [action.payload.id]: true,
        }
      };
    case USER_ACCEPT_REGISTER_GREFFER_SUCCEEDED:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.id]: action.payload
        },
        acceptLoading: {
          ...state.acceptLoading,
          [action.payload.id]: undefined,
        },
      };
    case USER_ACCEPT_REGISTER_GREFFER_FAILED:
      return {
        ...state,
        acceptLoading: {
          ...state.acceptLoading,
          [action.payload.id]: undefined,
        },
      };
    default:
      return state;
  }
}

export default greffer;
