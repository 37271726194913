import fetchAbsolute from 'fetch-absolute';
import * as api from './index';

const fetchApi = fetchAbsolute(fetch)(api.BASE_URL);

function defaultHeader() {
  const token = sessionStorage.getItem('user_token');
  return token && {
    Authorization: `Token ${token}`,
  };
}
async function request(method, query, { data, headers }) {
  const res = await fetchApi(query, {
    method,
    headers: {
      ...defaultHeader(),
      'Content-Type': 'application/json',
      ...headers,
    },
    body: data && JSON.stringify(data),
  });
  if(res.status < 200  || res.status > 300) {
    const err =  new Error('Bad Status', { cause: { status: res.status }})
    err.response = res
    throw err
  }
  return res
}
const instance = {
  get(query, { data, headers }) {
    return request('GET', query, { data, headers })
  },
  post(query, { data, headers }) {
    return request('POST', query, { data, headers })
  },
  put(query, { data, headers }) {
    return request('PUT', query, { data, headers })
  },
};

export default instance;
