import React from 'react';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-eva/file-add-fill';
import Button from '@mui/material/Button';

function AddButton(props) {
  return (
    <Button {...props} color="primary">
      <Icon icon={add} />
      {' '}
      Add Item
    </Button>
  );
}

export default AddButton;
