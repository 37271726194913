import * as Yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import Dialog from '../Dialog';

export default function FormDialog({
  open, onClose, onCreateGreffer, loading,
}) {
  const GreffonSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: GreffonSchema,
    onSubmit: ({ name }) => {
      onCreateGreffer({ name });
    },
  });

  const {
    errors, touched, handleSubmit, getFieldProps,
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Create your Greffon</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" loading={loading} onClick={handleSubmit}>Save</LoadingButton>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
}
