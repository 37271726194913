import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import user from './user';
import notification from './notification';
import greffon from './greffon';
import greffer from './greffer';
import greffonCategories from './greffonCategories';
import mySaga from '../sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// mount it on the Store
const store = createStore(
  combineReducers({
    user,
    notification,
    greffon,
    greffonCategories,
    greffer,
  }),
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

// then run the saga
sagaMiddleware.run(mySaga);

export default store;
