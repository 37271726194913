import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import GreffonCard from './GreffonCard';
import Presentational from '../presentational/Presentational'

// ----------------------------------------------------------------------

GreffonList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function GreffonList({ ids, Component }) {
  return (
    <Grid container spacing={3}>
      {ids.map((id) => (
        <Grid key={id} item xs={12} sm={6} md={3}>
          <Presentational Component={Component} id={id}/>
        </Grid>
      ))}
    </Grid>
  );
}
