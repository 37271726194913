import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react'
import monitorOutline from '@iconify/icons-eva/monitor-outline';
// material
import {
  Box, Card, Link, Grid, Typography, Button, Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

import * as status from '../../constants/status';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const buttons = {
  [status.GREFFER_CREATED]: () => {},
  [status.GREFFER_REGISTERING]: ({ onAcceptRegister, acceptLoading }) => (<LoadingButton variant="contained" onClick={onAcceptRegister} loading={acceptLoading}>Accept</LoadingButton>),
  [status.GREFFER_REGISTERED]: () => { },
}
export default function GrefferCard({ greffer, onRegister, onAcceptRegister, acceptLoading }) {
  const { name, id, status } = greffer;

  return (
    <Card sx={{
      height: '100%'
    }}>
      <Grid container direction="column">
        <Grid container>
          <Grid item xs={2}>
            <Icon icon={monitorOutline} width={"100%"} height={"100%"} />
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Stack spacing={3} sx={{ p: 1.5 }}>
            <Link to="#" color="inherit" underline="hover" component={RouterLink}>
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>
            </Link>
            <Link to="#" color="inherit" underline="hover" component={RouterLink}>
              <Typography variant="subtitle2" noWrap>
                {id}
              </Typography>
            </Link>

            <Stack direction="row-reverse" alignItems="center" justifyContent="space-between">
              {buttons[status]({ onRegister, onAcceptRegister, acceptLoading })}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
