import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import FilterSidebar from './Filter';
import Greffon from './Greffon'
import { createSelector } from 'reselect'
import {
  MyGreffonSort,
  MyGreffonList,
  MyGreffonFilterSidebar,
  MyGreffonConfigurationDialog,
} from '../../components/my-greffon';
import {
  USER_CLOSE_CONFIGURING_MY_GREFFON,
  USER_UPDATE_MY_GREFFON_REQUESTED,
  USER_OPEN_MY_GREFFON_PAGE,
  USER_CLOSE_MY_GREFFON_PAGE,
} from '../../constants/actions';
import withRouter from '../../components/withRouter';
import { VALUE_TO_STATUS } from './constant';

// ----------------------------------------------------------------------

function GreffonList({
  loading, greffonIds, enterOnMyGreffon, leaveMyGreffon, configuringGreffon,
   configurationDialog, onCloseConfigurationDialog,
   updateGreffon
}) {
  useEffect(() => {
    enterOnMyGreffon();
    return leaveMyGreffon
  }, []);
  return (
    <Page title="Dashboard: My Greffons">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          My Greffons
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          {/* <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <FilterSidebar/>
            <MyGreffonSort />
          </Stack> */}
        </Stack>
        {configurationDialog && (
        <MyGreffonConfigurationDialog
          loading={loading}
          open={configurationDialog}
          greffon={configuringGreffon}
          onClose={onCloseConfigurationDialog}
          onSave={updateGreffon}
        />
        )}
        <MyGreffonList
          ids={greffonIds}
          Component={Greffon}
        />
      </Container>
    </Page>
  );
}

const mapDispatchToProps = (dispatch, { router, enqueueSnackbar }) => ({
  // dispatching actions returned by action creators
  enterOnMyGreffon: (payload) => dispatch({
    type: USER_OPEN_MY_GREFFON_PAGE,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  leaveMyGreffon: (payload) => dispatch({
    type: USER_CLOSE_MY_GREFFON_PAGE,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  updateGreffon: (payload) => dispatch({
    type: USER_UPDATE_MY_GREFFON_REQUESTED,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  onCloseConfigurationDialog: (payload) => dispatch({
    type: USER_CLOSE_CONFIGURING_MY_GREFFON,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
});
const selectGreffonIds = state => state.greffon.me.ids
const selectGreffons = state => state.greffon.me.values
const selectFilter = state => state.greffon.me.filter
const greffonIds = createSelector([selectGreffonIds, selectGreffons, selectFilter], (ids, values, filter) => {
  if(filter?.status?.length || filter?.categories?.length) { 
    return ids.filter((id) => {
    const categories = values[id].categories.map(item => item.name)
    return filter.status?.indexOf(VALUE_TO_STATUS[values[id].status]) !== -1 || filter.categories?.filter(category => categories.indexOf(category) !== -1)?.length 
    }
  )}
  return ids
})
export default compose(withSnackbar, withRouter, connect((state, ownProps) => ({
  loading: state.greffon.me.loading,
  greffonIds: greffonIds(state),
  configurationDialog: state.greffon.me.configuring,
  configuringGreffon: state.greffon.me.configuringGreffon,
}), mapDispatchToProps))(GreffonList);
