import { connect } from 'react-redux';
import { compose } from 'redux';
import { MyGreffonCard } from '../../components/my-greffon'
import withRouter from '../../components/withRouter'
import { withSnackbar } from 'notistack'
import {
    USER_CONFIGURING_MY_GREFFON,
    USER_OPEN_MY_GREFFON_REQUESTED,
    USER_START_MY_GREFFON_REQUESTED,
    USER_STOP_MY_GREFFON_REQUESTED
} from '../../constants/actions';

function GreffonContainer({ greffon, startLoading, stopLoading, onStart, onStop, onOpen, onOpenConfigurationDialog }) {
    return (
        <MyGreffonCard greffon={greffon}
            loading={startLoading || stopLoading}
            onStart={() => onStart(greffon)}
            onStop={() => onStop(greffon)}
            onOpen={() => onOpen(greffon)}
            onConfigure={() => onOpenConfigurationDialog(greffon)} />
    )
}

const mapDispatchToProps = (dispatch, { router, enqueueSnackbar }) => ({
    onStart: (payload) => dispatch({
        type: USER_START_MY_GREFFON_REQUESTED,
        payload,
        navigate: router?.navigate,
        enqueueSnackbar,
    }),
    onStop: (payload) => dispatch({
        type: USER_STOP_MY_GREFFON_REQUESTED,
        payload,
        navigate: router?.navigate,
        enqueueSnackbar,
    }),
    onOpen: (payload) => dispatch({
        type: USER_OPEN_MY_GREFFON_REQUESTED,
        payload,
        navigate: router?.navigate,
        enqueueSnackbar,
    }),
    onOpenConfigurationDialog: (payload) => dispatch({
        type: USER_CONFIGURING_MY_GREFFON,
        payload,
        navigate: router?.navigate,
        enqueueSnackbar,
    }),
});

const mapStateProps = (state, { id }) => ({
    greffon: state.greffon.me.values[id],
    startLoading: state.greffon.me.startLoading[id],
    stopLoading: state.greffon.me.stopLoading[id],
})
export default compose(withSnackbar, withRouter, connect(mapStateProps, mapDispatchToProps))(GreffonContainer);

