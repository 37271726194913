import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import {
  GreffonSort,
  GreffonList,
  GreffonFilterSidebar,
  GreffonDialog,
} from '../../components/greffon';
import GreffonCard from './Greffon'
import {
  USER_CREATE_MY_GREFFON_REQUEST,
  USER_GET_ALL_GREFFONS_REQUESTED,
  USER_CLOSE_CREATING_GREFFON,
  USER_CREATING_GREFFON,
} from '../../constants/actions';
import withRouter from '../../components/withRouter';

// ----------------------------------------------------------------------

function Greffon({
  createDialogOpen, onOpenCreateDialogGreffon, onCreateGreffon, OnCreateDialogClose, greffonIds, creatingGreffon, buyDialogOpen, greffers, getGreffons,
}) {
  const [openFilter, setOpenFilter] = useState(false);
  useEffect(() => {
    getGreffons();
  }, []);
  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: '',
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  return (
    <Page title="Dashboard: Greffons">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Greffons
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <GreffonFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <GreffonSort /> */}
          </Stack>
        </Stack>
        {(createDialogOpen || buyDialogOpen) && (
        <GreffonDialog
          open={true}
          onCreateGreffon={onCreateGreffon}
          onClose={OnCreateDialogClose}
          greffon={creatingGreffon}
          greffers={greffers}
          withGreffer={createDialogOpen}
        />
        )}
        <GreffonList Component={GreffonCard} ids={greffonIds} />
      </Container>
    </Page>
  );
}

const mapDispatchToProps = (dispatch, { router, enqueueSnackbar }) => ({
  // dispatching actions returned by action creators
  getGreffons: (payload) => dispatch({
    type: USER_GET_ALL_GREFFONS_REQUESTED,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  onOpenCreateDialogGreffon: (payload) => dispatch({
    type: USER_CREATING_GREFFON,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  OnCreateDialogClose: (payload) => dispatch({
    type: USER_CLOSE_CREATING_GREFFON,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
  onCreateGreffon: (payload) => dispatch({
    type: USER_CREATE_MY_GREFFON_REQUEST,
    payload,
    navigate: router?.navigate,
    enqueueSnackbar,
  }),
});
export default compose(withSnackbar, withRouter, connect((state, ownProps) => ({
  greffonIds: state.greffon.ids,
  greffers: state.greffer.ids.map((id) => state.greffer.values[id]),
  createDialogOpen: state.greffon.creating,
  buyDialogOpen: state.greffon.buying,
  creatingGreffon: state.greffon.creatingGreffon,
}), mapDispatchToProps))(Greffon);
